/* EVENTIAL CSS

	1. CORE CSS
	2. NAVIGATION
	3. TOP
	   - Countdown
	4. OVERVIEW
	   - Milestone
	5. SPEAKERS
	6. SCHEDULE
	7. VENUE
	8. TESTIMONIAL
	9. SPONSORS
	10. SUBSCRIBE
	11. FAQ & GALLERY
	12. REGISTER
	13. CONTACT 
	14. MISC.
		- Form Elements
		- Buttons
		- Preload	
*/

/* CORE CSS */


body {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 1.4em;
    line-height: 1.5em;
    overflow-x: hidden;
}

section {
    padding: 35px 0;
}

h2 {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 27px;
    margin-top: 0;
    margin-bottom: 40px;
}

h3 {
    font-family: 'Roboto';
    font-weight: 500;
}

h4 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 17px;
    letter-spacing: -1px;
    margin-bottom: 5px;
}

.lead {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 1px;
}

.uppercase {
    text-transform: uppercase;
}

/* NAVIGATION */

.navbar {
    -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
    -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
    transition: background .5s ease-in-out, padding .5s ease-in-out;
}

.navbar-custom {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 12px;
    border-radius: 0;
    margin-bottom: 0;
    padding: 50px 0;
}

.navbar-brand {
    padding: 0 15px;
    height: 80px;
    margin-top: -15px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.navbar-brand img {
    height: 80px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.navbar-custom .navbar-nav > li > a:hover {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.top-nav-collapse {
    padding: 20px 0;
}
.top-nav-collapse .navbar-brand {
    height: 50px;
    margin-top: 0;
}

.top-nav-collapse .navbar-brand img {
    height: 50px;
}


/* TOP */

#top {
    background: url(../../../media/bg1.png) bottom center no-repeat;
    width: 100%;
    max-height: auto;
    height: 600px;
    margin-bottom: 15px;
    position: relative;
    display: table;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

#top h1 {
    font-family: 'Roboto Condensed';
    font-size: 50px;
    margin-top: 2em;
}

/* Countdown */

.countdown {
    display: table-cell;
    vertical-align: middle;
}

#countdown {
    margin: 40px 0 90px 0;

}

#countdown .countdown_amount {
    font-family: 'Roboto';
    font-size: 50px;
    margin-bottom: 10px;
}

#countdown .countdown_section {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 10px;
}

/* OVERVIEW */

#overview h2 {
    margin: 10px 0;
}

#overview h3 span {
    font-size: 30px;
    vertical-align: middle;
}

#overview h4 {
    margin-bottom: 20px;
}

#overview .lead {
    letter-spacing: 0px;
}

/* Milestone */

#milestone {
    width: 100%;
    padding: 30px 0;
    overflow: hidden;
    z-index: 0;
    height: auto;
    display: table;
}

.fact {
    text-transform: uppercase;
    display: table-cell;
    vertical-align: middle;

}

.fact .timer {
    font-family: 'Roboto Condensed';
    font-weight: 300;
    font-size: 55px;
    line-height: 55px;
}

.fact p {
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 700;
    letter-spacing: 1px;
}

.fact i {
    vertical-align: middle;
    margin: 10px 0 5px 0;
}

/* SPEAKER */

.speaker {
    margin-bottom: 30px;
}

.speaker img {
    width: 100%;
    display: block;
}

.speaker figure {
    overflow: hidden;
    position: relative;
    display: table;
    width: 100%;
}

.speaker figcaption {
    display: table-cell;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
    -webkit-transition-delay: 100ms;
    -moz-transition-delay: 100ms;
    transition-delay: 100ms;
}

.speaker figcaption p {
    font-size: 0.8em;
}

.speaker:hover figcaption {
    opacity: 1;
}

.speaker:hover, .speaker:active {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -webkit-touch-callout: none
}

.speaker .social {
    position: absolute;
    bottom: 0px;
    right: 10px;
}

.speaker a,
.speaker .social a {
    text-decoration: none;
    margin: 0;
    padding: 0;
}

.speaker a:hover,
.speaker .social a:hover {
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.speaker .title {
    font-family: 'Roboto Condensed';
    font-weight: 300;
    font-size: 12px;
}

/* speaker detail popup */

#speaker-detail {
    padding: 45px;
}

#speaker-detail h2 {
    font-size: 22px;
    margin-bottom: 10px;
}

#speaker-detail h3 {
    font-size: 16px;
    margin-top: 10px;
}

#speaker-detail a {
    font-size: 13px;
    margin-top: 5px;
}

#speaker-detail a:hover {
    text-decoration: none;
}

#speaker-detail i {
    vertical-align: middle;
}

/* SCHEDULE */

.event {
    display: table;
    width: 100%;
    border-spacing: 10px 5px;
    height: 110px;
}

.event-inner {
    display: table-row;
}

.event h3 {
    margin-top: 0;
    font-size: 17px;
}

.event .icon {
    width: 16%;
    padding: 5px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.event .icon i {
    display: block;
    margin-bottom: 10px;
}

.event .icon .time {
    font-family: 'Roboto Condensed';
    font-size: 22px;
    font-weight: 700;
}

.event .description {
    padding: 15px;
    display: table-cell;
    vertical-align: middle;
}

.event .description p {
    font-size: 14px;
}

.event .name {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
}

/* Schedule Pagination */

#days .item {
    margin: 10px;
    padding: 10px;
}

#days .item:hover,
#days .item:active {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

#days .item h4,
#days .item h5 {
    font-family: 'Roboto';
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
}

/* VENUE */

#venue {
    padding: 0 0 80px 0;
}

.venue {
    background: url(../../../media/bg1.png) bottom center no-repeat;
    width: 100%;
    height: auto;
    min-height: 410px;
    margin-bottom: 15px;
    position: relative;
}

.venue-address {
    padding: 15px;
    margin: 0 15px;
    min-height: 250px;
    position: absolute;
    bottom: 0;
}

#venue h3 {
    margin: 30px 0;
}

#venue .venue-address h2 {
    margin: 0 0 15px 0;
}

#venue h4 {
    font-family: 'Roboto';
    font-weight: 500;
    margin-bottom: 15px;
}

.venue .address {
    font-size: 16px;
    margin-top: 25px;
}

#venue i {
    margin: 0 10px 0 0;
    float: left;
    vertical-align: middle;
}

/* TESTIMONIAL */

#testimonial {
    /* background: url(../../../media/bg2.png)bottom center no-repeat fixed; */
    background-repeat: no-repeat;
    background-position: bottom center;
    background-attachment: fixed;
    width: 100%;
    height: auto;
    min-height: 225px;
    margin-bottom: 15px;
    padding: 10px 0 20px 0;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

#testimonial .item {
    margin: 0 15px;
}

#testimonial img {
    margin-bottom: 20px;
    vertical-align: middle;
}

#testimonial .item div {
    display: table-cell;
    vertical-align: middle;
}

#testimonial p {
    font-size: 17px;
}

#testimonial h4 {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;
}

#quote {
    padding-top: 30px;
}

/* SPONSOR */

#sponsors .lead {
    font-size: 20px;
}

#sponsors h3 {
    font-size: 20px;
}

/* SUBSCRIBE */

#subscribe {
    /* background: url(../../../media/bg2.png) bottomr center no-repeat fixed; */
    background-repeat: no-repeat;
    background-position: bottom center;
    background-attachment: fixed;
    padding: 50px 0;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

#subscribe h3 {
    margin-bottom: 20px;
}

#subscribe input {
    border-radius: 4px;
}

#subscribe .button {
    margin: 0;
}

/* FAQ - GALLERY */

#faq-gallery {
    padding: 70px 0;
}

#faq-gallery p {
    margin-bottom: 20px;
}

#faq-gallery a:hover {
    text-decoration: none;
}

#faq i {
    margin-right: 10px;
    vertical-align: middle;
}

#gallery img {
    margin-bottom: 30px;
}

#gallery img:hover {
    opacity: 0.5;
    filter: Alpha(opacity=50); /* IE8 and earlier */
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

/* REGISTER */

#register {
    padding: 70px 0;
    /* background: url(../../../media/bg2.png) center center no-repeat fixed; */
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.price-table-header {
    padding: 30px 0;
    display: table;
    height: auto;
    cursor: pointer;
}

.price-table-header .price {
    font-family: 'Roboto Condensed';
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 61px;
}

.price-table-header .title {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 22px;
    padding-bottom: 20px;
    line-height: 22px;
}

.price-table-description {
    width: 100%;
    margin: 0;
    padding: 0;
    height: auto;
    display: table;
    list-style-type: none;
}

.description-item {
    padding: 10px 0;
}

#register .lead {
    font-size: 31px;
}

#register .timer {
    font-size: 36px;
}

/* register form */

#register-form {
    padding: 25px;
}

#register-form h2 {
    margin-bottom: 15px;
}

.register-form {
    margin-top: 20px;
}

/* CONTACT */

#contact {
    font-size: 16px;
    padding: 0;
    position: relative;
}

.contact {
    padding: 15px;
    margin: 0 15px 0 10px;
    height: 576px;
    position: absolute;
    bottom: 0;
}

.contact h2 {
    margin-bottom: 10px;
}

#contact p {
    float: left;
}

#contact .address {
    font-size: 21px;
    margin: 20px 0;
}

#contact .social {
    position: absolute;
    bottom: 5px;
}

#gmap_canvas {
    position: relative;
    height: 576px;
    width: 100%;
}

#gmap_canvas img {
    max-width: none !important;
    background: none !important
}

.social {
    margin: 10px 0;
}

.social .fa-inverse {
}

.social a {
    text-decoration: none;
    margin: 0;
    padding: 0;
}

.social a:hover {
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

/* MISC */

/* FORM ELEMENTS */

input[type="text"],
input[type="email"],
select {
    font-size: 18px;
    font-family: inherit;
    width: 100%;
    min-height: 30px;
    padding: 12px 10px;
     border: 0;
    border: 1px solid #2e2e2e;
    margin-bottom: 20px;
    outline: none;
}

/* BUTTONS */

.button {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 10px;
    text-transform: uppercase;
    border: none;
    margin: 15px 0;
    padding: 5px 15px;
    display: inline-block;
    position: relative;
    outline: none;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.button:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.button-big {
    padding: 15px 25px;
    font-size: 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

.button-light:hover,
.button-light:active {
    text-decoration: none;
}

.button-dark:hover,
.button-dark:active {
    text-decoration: none;
}

/* PRELOAD */

#preload {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: table;
}

.preload {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.loader {
    margin: 3em auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


@media (max-width: 991px) {
    #top h1{
        font-size: 20px;
    }
}
@media (min-width: 992px) and (max-width: 1023px) {

}
@media (min-width: 1024px) and (max-width: 1199px) {

}
@media (min-width: 1200px) {

}

